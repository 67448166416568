import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';

import GoogleMapReact from 'google-map-react';

const Marker = () => (<i className="fa fa-map-marker fa-5x text-danger ml-n3 mt-n5"></i>);

class PostAdvertModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isOpenAdvertLangugeAddModal: false,

      selectedCategories: [],

      advertAttributes: [],
      advertFields: [],
      advertLanguages: [],
      datas: {},

      countryId: 1,
      countryName: 'Türkiye',
      cityId: 0,
      cityName: '',
      townId: 0,
      townName: '',
      districtId: 0,
      districtName: '',
      quarterId: 0,
      quarterName: '',

      lat: 0,
      lng: 0,
      zoom: 7,

      countries: [],
      cities: [],
      towns: [],
      regions: [],
      quarters: [],

      selectedLanguageIndex: -1,
      content: ''
    };
  }

  componentDidMount() {
    const selectedCategories = JSON.parse(localStorage.getItem('selectedCategories'));
    this.setState({selectedCategories: selectedCategories})
    window.scrollTo(0, 0);
    console.log("PostAdverts: componentDidMount()");
  }

  componentWillUnmount() {
    console.log("PostAdverts: componentWillUnmount()");
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="mt-2">
        <div className="row">
          <div className="col d-flex justify-content-between">
            <h3 className="text-darkblue">Ücretsiz İlan Ver</h3>
            <Link className="text-underline-orange text-orange" to={'/createAdvert'}>Kategori Değişikliği Yapmak İçin Tıklayınız</Link>
          </div>
        </div>
        <div className="hr-line-dashed mt-0"></div>
        <div className="row mb-5">
          <div className="col d-flex justify-content-between">
            <div className="d-flex">
              <p className="mr-2 text-orange">İlan Vermek İstediğiniz Kategori Seçiminiz: </p>
              <ul className="d-flex list-unstyled">
                {this.state.selectedCategories && this.state.selectedCategories.map((category, index) => (
                  category.categoryId > 0 || index > 0 ? 
                  <li key={index} className="breadcrumb-item">
                    <a className="text-darkblue" href="#" >
                      {category.categoryName}
                    </a>
                  </li>
                  : null
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <h5 className="text-darkblue"><strong>İlan Başlığı<strong className="text-danger ml-1">*</strong></strong></h5>
            <input className="form-control form-input mb-2 mt-1" placeholder="İlan Başlığı" type="text"/>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <h5 className="text-darkblue"><strong>İlan Açıklaması<strong className="text-danger ml-1">*</strong></strong></h5>
            <textarea className="form-control form-input mb-2 mt-1" placeholder="İlan Açıklaması" type="text" />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col">
            <h5 className="text-darkblue"><strong>İlan İçeriği<strong className="text-danger ml-1">*</strong></strong></h5>
            <textarea className="form-control form-input mb-2 mt-1" placeholder="İlan İçeriği" type="text"></textarea>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-12">
            <h4 className="text-darkblue">İlana Ait Özel Alanlar</h4>
            <div className="hr-line-dashed mt-0"></div>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-12">
            <h4 className="text-darkblue">İlana Ait Özellikler</h4>
            <div className="hr-line-dashed mt-0"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-3">
            <div className="google-maps">
              {this.state.lat !== null && this.state.lng !== null &&
                <GoogleMapReact bootstrapURLKeys={{key: "AIzaSyDFDWC7Et5MZXsiJJ2qd_NTT6JdENiMIcM"}} defaultCenter={{lat: this.state.lat, lng: this.state.lng}} defaultZoom={this.state.zoom} onClick={(event) => this.setState({lat: event.lat, lng: event.lng})}>
                  <Marker lat={this.state.lat} lng={this.state.lng}></Marker>
                </GoogleMapReact>
              }
            </div>
          </div>
        </div>
      </div>
  }
}

export default function PostAdvert() {
  return (
    <PostAdvertModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}