import React from 'react';
import Modal from 'react-modal';

import swal from 'sweetalert';

import config from '../inc/config';
import {getCookie} from '../inc/cookies';

export class CustomWidgetModal extends React.Component {

  componentDidMount() {
    console.log("CustomWidgetModal: componentDidMount()");
  }

  componentWillUnmount() {
    console.log("CustomWidgetModal: componentWillUnmount()");
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onRequestClose}
        style={{
          content : {
            position : 'null',
            top : 'null',
            left : 'null',
            right : 'null',
            bottom : 'null',
            border : 'none',
            background : '#fff',
            padding : 'null',
            maxWidth : '50%',
            minWidth : '350px'
          },
          overlay : {
            position : 'fixed',
            top : 0,
            left : 0,
            right : 0,
            bottom : 0,
            display : 'flex',
            alignItems : 'center',
            justifyContent : 'center',
            backgroundColor : 'rgba(0,0,0,00.1)',
            zIndex: "1000"
          }
        }}

      >
      <div className="ibox-content no-padding">
        <div className="modal-header d-block text-darkblue">
          <button className="close" onClick={this.props.onRequestClose}><span>×</span></button>
          <h4 className="modal-title">{this.props.modalTitle}</h4>
          <h5 className="font-normal">{this.props.modalDescription}</h5>
        </div>
        <div className="modal-body text-darkblue overflow-auto" style={{maxHeight: '400px'}}>
          {this.props.children}
        </div>
        <div className="modal-footer">
          <button className="btn btn-orange" onClick={this.props.onSubmit}>Tamam</button>
        </div>
      </div>
      </Modal>
    )
  }
}

export class CustomWidget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryList: [],
      isOpen: false,
      defaultValue: '',
      mainValue: '',
      maxValue: '',
      minValue: '',
      preValue: ''
    };
  }

  dateFormat(date) {
    let parsedValue = /^(\d{4})-(\d{2})-(\d{2})$/.exec(date);
    return parsedValue ? parsedValue[3] + '.' + parsedValue[2] + '.' + parsedValue[1] : '';
  }

  phoneFormat(phone) {
    let parsedValue = /^(\d{3})(\d{3})(\d{2})(\d{2})$/.exec(phone);
    return parsedValue ? '(' + parsedValue[1] + ') ' + parsedValue[2] + ' ' + parsedValue[3] + ' ' + parsedValue[4] : phone;
  }

  priceFormat(price) {
    return new Intl.NumberFormat('tr-TR', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(price);
  }

  rangeFormat(parsedValue) {
    return parsedValue ? parsedValue[1] + ' - ' + parsedValue[2] : '';
  }

  swalBox(icon, title, text, callback) {
    swal({
      dangerMode: true,
      icon: icon,
      title: title,
      text: text,
      buttons: {confirm: 'Tamam'}
    }).then(value => callback && callback(value));
  }

  updateDefaultValue(key, value) {
    let defaults = this.props.defaults;
    defaults && key && (value ? defaults[key] = value : delete defaults[key]);
    return defaults;
  }

  widgetFetch(url, complete) {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + sessionHash}
    };

    fetch(url, requestOptions)
    .then(response => response.json())
    .then(responseData => complete(responseData), () => complete(null));
  }

  addAlertCheckBox(defaultValue, parameters) {
    let selectedValues = (defaultValue && defaultValue.split(',')) || [];
    let selectedNames = this.props.fieldInfo.values.filter(item => selectedValues.includes(item.value)).map(item => item.name);

    return (
      <>
        <div className="mb-1 p-sm bg-widget" onClick={() => !parameters.readOnly && this.setState({defaultValue: defaultValue, isOpen: true})}>
          <h4>{this.props.fieldInfo.optionName}</h4>
          <span>{selectedNames.join(', ') || 'Seçilmedi'}</span>
        </div>
        <CustomWidgetModal isOpen={this.state.isOpen} modalTitle={this.props.fieldInfo.optionName} onRequestClose={() => this.setState({isOpen: false})} onSubmit={() => this.setState({isOpen: false}, () => this.props.defaultOnChange(this.updateDefaultValue(this.props.fieldInfo.optionId, selectedValues.join(','))))}>
          {this.props.fieldInfo.values.map(item => <label key={item.value} className="pointer w-100 m-0 p-h-xs"><input defaultChecked={selectedValues.includes(item.value)} onChange={event => event.target.checked ? selectedValues.push(item.value) : selectedValues = selectedValues.filter(filterItem => filterItem !== item.value)} type="checkbox" /> {item.name}</label>)}
        </CustomWidgetModal>
      </>
    )
  }

  addAlertCheckBoxGroup() {
    //console.log('addAlertCheckBoxGroup');
  }

  addAlertEditText(defaultValue, parameters) {
    return (
      <>
        <div className="mb-1 p-sm bg-widget" onClick={() => !parameters.readOnly && this.setState({defaultValue: defaultValue, isOpen: true})}>
          <h4>{this.props.fieldInfo.optionName}</h4>
          <span>{defaultValue || 'Girilmedi'}</span>
        </div>
        <CustomWidgetModal isOpen={this.state.isOpen} modalTitle={this.props.fieldInfo.optionName} onRequestClose={() => this.setState({isOpen: false})} onSubmit={() => this.setState({isOpen: false}, () => this.props.defaultOnChange(this.updateDefaultValue(this.props.fieldInfo.optionId, this.state.defaultValue)))}>
          <input className="form-control form-input" onChange={event => this.setState({defaultValue: event.target.value})} type="text" value={this.state.defaultValue} />
        </CustomWidgetModal>
      </>
    )
  }

  addAlertNumberText(defaultValue, parameters) {
    return (
      <>
        <div className="mb-1 p-sm bg-widget" onClick={() => !parameters.readOnly && this.setState({defaultValue: defaultValue, isOpen: true})}>
          <h4>{this.props.fieldInfo.optionName}</h4>
          <span>{defaultValue || 'Girilmedi'}</span>
        </div>
        <CustomWidgetModal isOpen={this.state.isOpen} modalTitle={this.props.fieldInfo.optionName} onRequestClose={() => this.setState({isOpen: false})} onSubmit={() => this.setState({isOpen: false}, () => this.props.defaultOnChange(this.updateDefaultValue(this.props.fieldInfo.optionId, this.state.defaultValue)))}>
          <input className="form-control form-input" onChange={event => this.setState({defaultValue: event.target.value.replace(/\D*/g, '')})} type="number" value={this.state.defaultValue} />
        </CustomWidgetModal>
      </>
    )
  }

  addAlertPhoneNumber(defaultValue, parameters) {
    let parsedValue = /^(\d*)\|(\d{0}|\d{10})$/.exec(defaultValue);

    let selectedValue = (parsedValue && this.props.fieldInfo.values.filter(item => parsedValue[1] === item.value)) || [];

    let codeName = '';
    let codeValue = '';
    let phoneValue = '';

    if (selectedValue.length > 0) {
      codeName = selectedValue[0].name;
      codeValue = selectedValue[0].value;
      phoneValue = parsedValue[2];
    }

    let onSubmit = () => {
      if (!this.state.preValue && this.state.mainValue) {
        this.swalBox('warning', 'Hata oluştu', 'Lütfen ülke kodunu seçiniz.', null);
      } else if (this.state.mainValue && this.state.mainValue.length !== 10) {
        this.swalBox('warning', 'Hata oluştu', 'Lütfen telefon numarasını kontrol ediniz.', null);
      } else {
        this.setState({isOpen: false}, () => this.props.defaultOnChange(this.updateDefaultValue(this.props.fieldInfo.optionId, this.state.mainValue ? this.state.preValue + '|' + this.state.mainValue : '')));
      }
    }

    return (
      <>
        <div className="mb-1 p-sm bg-widget" onClick={() => !parameters.readOnly && this.setState({mainValue: phoneValue, preValue: codeValue || (this.props.fieldInfo.values.length > 0 && this.props.fieldInfo.values[0].value), isOpen: true})}>
          <h4>{this.props.fieldInfo.optionName}</h4>
          <span>{selectedValue.length > 0 ? codeName + ' ' + this.phoneFormat(phoneValue) : 'Girilmedi'}</span>
        </div>
        <CustomWidgetModal isOpen={this.state.isOpen} modalTitle={this.props.fieldInfo.optionName} onRequestClose={() => this.setState({isOpen: false})} onSubmit={() => onSubmit()}>
          <select className="form-control form-input" onChange={event => this.setState({preValue: event.target.value})} value={this.state.preValue}>{this.props.fieldInfo.values.map(item => <option key={item.value} value={item.value}>{item.name}</option>)}</select>
          <input className="form-control form-input" maxLength={10} onChange={event => this.setState({mainValue: event.target.value.replace(/[^0-9.]*/g, '')})} placeholder="Telefon" type="text" value={this.state.mainValue} />
        </CustomWidgetModal>
      </>
    )
  }

  addAlertPrice(defaultValue, parameters) {
    let parsedValue = /^(\d*)\|([0-9.]*)$/.exec(defaultValue);

    let selectedValue = (parsedValue && this.props.fieldInfo.values.filter(item => parsedValue[1] === item.value)) || [];

    let currencyName = '';
    let currencyValue = '';
    let priceValue = '';

    if (selectedValue.length > 0) {
      currencyName = selectedValue[0].name;
      currencyValue = selectedValue[0].value;
      priceValue = parsedValue[2];
    }

    let onSubmit = () => {
      if (!this.state.preValue && this.state.mainValue) {
        this.swalBox('warning', 'Hata oluştu', 'Lütfen para birimini seçiniz.', null);
      } else {
        this.setState({isOpen: false}, () => this.props.defaultOnChange(this.updateDefaultValue(this.props.fieldInfo.optionId, this.state.mainValue ? this.state.preValue + '|' + this.state.mainValue : '')));
      }
    }

    return (
      <>
        <div className="mb-1 p-sm bg-widget" onClick={() => !parameters.readOnly && this.setState({mainValue: priceValue, preValue: currencyValue || (this.props.fieldInfo.values.length > 0 && this.props.fieldInfo.values[0].value), isOpen: true})}>
          <h4>{this.props.fieldInfo.optionName}</h4>
          <span>{selectedValue.length > 0 ? this.priceFormat(priceValue) + ' ' + currencyName : 'Girilmedi'}</span>
        </div>
        <CustomWidgetModal isOpen={this.state.isOpen} modalTitle={this.props.fieldInfo.optionName} onRequestClose={() => this.setState({isOpen: false})} onSubmit={() => onSubmit()}>
          <select className="form-control form-input" onChange={event => this.setState({preValue: event.target.value})} value={this.state.preValue}>{this.props.fieldInfo.values.map(item => <option key={item.value} value={item.value}>{item.name}</option>)}</select>
          <input className="form-control form-input" onChange={event => this.setState({mainValue: event.target.value.replace(/[^0-9.]*/g, '')})} placeholder="Tutar" type="text" value={this.state.mainValue} />
        </CustomWidgetModal>
      </>
    )
  }

  addAlertPriceRange(defaultValue, parameters) {
    let parsedValue = /^(\d*)\|([0-9.]*)-([0-9.]*)$/.exec(defaultValue);

    let selectedValue = (parsedValue && this.props.fieldInfo.values.filter(item => parsedValue[1] === item.value)) || [];

    let currencyName = '';
    let currencyValue = '';
    let maxValue = '';
    let minValue = '';

    if (selectedValue.length > 0) {
      currencyName = selectedValue[0].name;
      currencyValue = selectedValue[0].value;
      maxValue = parsedValue[3];
      minValue = parsedValue[2] || '0';
    }

    let onSubmit = () => {
      let maxValue = parseFloat(this.state.maxValue) || -1;
      let minValue = parseFloat(this.state.minValue) || 0;

      if (!this.state.preValue) {
        this.swalBox('warning', 'Hata oluştu', 'Lütfen para birimini seçiniz.', null);
      } else if (minValue > maxValue && maxValue !== -1) {
        this.swalBox('warning', 'Hata oluştu', 'Girilen aralığı kontrol ediniz.', null);
      } else {
        this.setState({isOpen: false}, () => this.props.defaultOnChange(this.updateDefaultValue(this.props.fieldInfo.optionId, this.state.preValue + '|' + minValue + '-' + (maxValue >= 0 ? maxValue : ''))));
      }
    }

    return (
      <>
        <div className="mb-1 p-sm bg-widget" onClick={() => !parameters.readOnly && this.setState({maxValue: maxValue, minValue: minValue, preValue: currencyValue || (this.props.fieldInfo.values.length > 0 && this.props.fieldInfo.values[0].value), isOpen: true})}>
          <h4>{this.props.fieldInfo.optionName}</h4>
          <span>{selectedValue.length > 0 ? this.priceFormat(minValue) + ' - ' + this.priceFormat(maxValue) + ' ' + currencyName : 'Girilmedi'}</span>
        </div>
        <CustomWidgetModal isOpen={this.state.isOpen} modalTitle={this.props.fieldInfo.optionName} onRequestClose={() => this.setState({isOpen: false})} onSubmit={() => onSubmit()}>
          <select className="form-control form-input" onChange={event => this.setState({preValue: event.target.value})} value={this.state.preValue}>{this.props.fieldInfo.values.map(item => <option key={item.value} value={item.value}>{item.name}</option>)}</select>
          <input className="form-control form-input" onChange={event => this.setState({minValue: event.target.value.replace(/[^0-9.]*/g, '')})} placeholder="Minumum" type="text" value={this.state.minValue} />
          <input className="form-control form-input" onChange={event => this.setState({maxValue: event.target.value.replace(/[^0-9.]*/g, '')})} placeholder="Maksimum" type="text" value={this.state.maxValue} />
        </CustomWidgetModal>
      </>
    )
  }

  addAlertRadioButton(defaultValue, parameters) {
    let selectedItem = this.props.fieldInfo.values.filter(item => defaultValue === item.value);
    let selectedItemName = selectedItem.length > 0 ? selectedItem[0].name : '';

    return (
      <>
        <div className="mb-1 p-sm bg-widget" onClick={() => !parameters.readOnly && this.setState({defaultValue: defaultValue, isOpen: true})}>
          <h4>{this.props.fieldInfo.optionName}</h4>
          <span>{selectedItemName || 'Seçilmedi'}</span>
        </div>
        <CustomWidgetModal isOpen={this.state.isOpen} modalTitle={this.props.fieldInfo.optionName} onRequestClose={() => this.setState({isOpen: false})} onSubmit={() => this.setState({isOpen: false}, () => this.props.defaultOnChange(this.updateDefaultValue(this.props.fieldInfo.optionId, this.state.defaultValue)))}>
          {this.props.fieldInfo.values.map(item => <label key={item.value} className="pointer w-100 m-0 p-h-xs"><input checked={this.state.defaultValue === item.value} name={this.props.fieldInfo.optionId} onChange={() => this.setState({defaultValue: item.value})} type="radio" /> {item.name}</label>)}
        </CustomWidgetModal>
      </>
    )
  }

  addAlertRange(defaultValue, parameters) {
    let parsedValue = /^(\d*)-(\d*)$/.exec(defaultValue);

    let onSubmit = () => {
      let maxValue = parseInt(this.state.maxValue) || -1;
      let minValue = parseInt(this.state.minValue) || 0;

      if (minValue > maxValue && maxValue !== -1) {
        this.swalBox('warning', 'Hata oluştu', 'Girilen aralığı kontrol ediniz', null);
      } else {
        this.setState({isOpen: false}, () => this.props.defaultOnChange(this.updateDefaultValue(this.props.fieldInfo.optionId, minValue + '-' + (maxValue >= 0 ? maxValue : ''))));
      }
    }

    return (
      <>
        <div className="mb-1 p-sm bg-widget" onClick={() => !parameters.readOnly && this.setState({maxValue: (parsedValue && parsedValue[2]) || '', minValue: (parsedValue && parsedValue[1]) || '0', isOpen: true})}>
          <h4>{this.props.fieldInfo.optionName}</h4>
          <span>{this.rangeFormat(parsedValue) || 'Girilmedi'}</span>
        </div>
        <CustomWidgetModal isOpen={this.state.isOpen} modalTitle={this.props.fieldInfo.optionName} onRequestClose={() => this.setState({isOpen: false})} onSubmit={() => onSubmit()}>
          <input className="form-control form-input" onChange={event => this.setState({minValue: event.target.value.replace(/\D*/g, '')})} placeholder="Minumum" type="number" value={this.state.minValue} />
          <input className="form-control form-input" onChange={event => this.setState({maxValue: event.target.value.replace(/\D*/g, '')})} placeholder="Maksimum" type="number" value={this.state.maxValue} />
        </CustomWidgetModal>
      </>
    )
  }

  addBrowseFile(_defaultValue, parameters) {
    //console.log('addBrowseFile');
  }

  //OK
  addCategories(defaultValue, parameters) {
    let changeCategory = (selectedCategoryInfo, index) => {
      let categoryList = this.state.categoryList;
      categoryList[index].default = (selectedCategoryInfo && selectedCategoryInfo.id.toString()) || '';
      categoryList.splice(index + 1);
      let deepCategory = categoryList.filter(item => item.default).pop();
      if (selectedCategoryInfo && selectedCategoryInfo.hasChild) {
        this.widgetFetch(config.restApi + 'categories/tr/' + selectedCategoryInfo.id, (response) => {
          categoryList.push({default: '', categories: (response && response.status === 200 && response.categories) || []});
          this.setState({categoryList: categoryList, defaultValue: (deepCategory && deepCategory.default) || ''});
        });
      } else {
        this.setState({categoryList: categoryList, defaultValue: (deepCategory && deepCategory.default) || ''});
      }
    }

    if (this.state.isOpen && this.state.categoryList.length === 0) {
      let categoryList = new Array(this.props.fieldInfo.values.length + 1);
      let remaining = categoryList.length;
      let complete = () => {
        remaining--;
        if (remaining === 0) {
          this.setState({categoryList: categoryList});
        }
      }

      this.widgetFetch(config.restApi + 'categories/tr/0', (response) => {
        categoryList[0] = {default: (this.props.fieldInfo.values[0] && this.props.fieldInfo.values[0].value) || '', categories: (response && response.status === 200 && response.categories) || []};
        complete();
      });

      this.props.fieldInfo.values.forEach((item, index) => {
        this.widgetFetch(config.restApi + 'categories/tr/' + item.value, (response) => {
          categoryList[index + 1] = {default: (this.props.fieldInfo.values[index + 1] && this.props.fieldInfo.values[index + 1].value) || '', categories: (response && response.status === 200 && response.categories) || []};
          complete();
        });
      });
    }

    return (
      <>
        <div className="mb-1 p-sm bg-widget" onClick={() => !parameters.readOnly && this.setState({defaultValue: defaultValue, isOpen: true})}>
          <h4>{this.props.fieldInfo.optionName}</h4>
          <span>{this.props.fieldInfo.values.map(item => item.name).join(' > ') || 'Seçilmedi'}</span>
        </div>
        <CustomWidgetModal isOpen={this.state.isOpen} modalTitle={this.props.fieldInfo.optionName} onRequestClose={() => this.setState({isOpen: false})} onSubmit={() => this.setState({isOpen: false}, () => this.props.defaultOnChange(this.updateDefaultValue(this.props.fieldInfo.optionId, this.state.defaultValue)))}>
          {this.state.categoryList.map((resultInfo, index) => <select key={index} className="form-control form-input" defaultValue={resultInfo.default} onChange={event => changeCategory(resultInfo.categories.filter(item => item.id.toString() === event.target.value)[0], index)}><option value="">Seçiniz</option>{resultInfo.categories.map(categoryInfo => <option key={categoryInfo.id} value={categoryInfo.id}>{categoryInfo.name}</option>)}</select>)}
        </CustomWidgetModal>
      </>
    )
  }

  addCheckBox(defaultValue, parameters) {
    let selectedValues = (defaultValue && defaultValue.split(',')) || [];

    return (
      <div className="mb-1 p-sm bg-widget">
        {this.props.fieldInfo.optionName && <h4>{this.props.fieldInfo.optionName}</h4>}
        {this.props.fieldInfo.values.map(item => <label key={item.value} className="pointer w-100 m-0 p-h-xs"><input checked={selectedValues.includes(item.value)} onChange={event => {event.target.checked ? selectedValues.push(item.value) : selectedValues = selectedValues.filter(filterItem => filterItem !== item.value);this.props.defaultOnChange(this.updateDefaultValue(this.props.fieldInfo.optionId, selectedValues.join(',')))}} readOnly={parameters.readOnly} type="checkbox" /> {item.name}</label>)}
      </div>
    )
  }

  addContractBox(defaultValue, parameters) {
    return (
      <>
        <div className="mb-1 p-sm bg-widget">
          <input checked={defaultValue === '1'} onChange={event => this.props.defaultOnChange(this.updateDefaultValue(this.props.fieldInfo.optionId, event.target.checked ? '1' : ''))} readOnly={parameters.readOnly} type="checkbox" />
          <span className="m-l-xs" onClick={() => !parameters.readOnly && this.setState({isOpen: true})}>{this.props.fieldInfo.optionName}</span>
        </div>
        <Modal
          isOpen={this.state.isOpen}
          onRequestClose={() => this.setState({isOpen: false})}
          style={{
            content : {
              position : 'null',
              top : 'null',
              left : 'null',
              right : 'null',
              bottom : 'null',
              border : 'none',
              background : '#fff',
              padding : 'null',
              maxWidth : '560px',
              minWidth : '400px'
            },
            overlay : {
              position : 'fixed',
              top : 0,
              left : 0,
              right : 0,
              bottom : 0,
              display : 'flex',
              alignItems : 'center',
              justifyContent : 'center',
              backgroundColor : 'rgba(0,0,0,00.1)',
              zIndex: "1000"
            }
          }}
        >
        <div className="ibox-content no-padding">
          <div className="modal-header d-block text-darkblue">
            <button className="close" onClick={() => this.setState({isOpen: false})}><span>×</span></button>
            <h4 className="modal-title">Sözleşme Koşulları</h4>
          </div>
          <iframe className="border-0 w-100" src={parameters.url} title={this.props.fieldInfo.optionName} />
          <div className="modal-footer">
            <button className="btn btn-green" onClick={() => this.setState({isOpen: false}, () => this.props.defaultOnChange(this.updateDefaultValue(this.props.fieldInfo.optionId, '1')))}>Kabul Et</button>
            <button className="btn btn-orange" onClick={() => this.setState({isOpen: false}, () => this.props.defaultOnChange(this.updateDefaultValue(this.props.fieldInfo.optionId, '')))}>Reddet</button>
          </div>
        </div>
        </Modal>
      </>
    )
  }

  addRadioButton(defaultValue, parameters) {
    return (
      <div className="mb-1 p-sm bg-widget">
        {this.props.fieldInfo.optionName && <h4>{this.props.fieldInfo.optionName}</h4>}
        {this.props.fieldInfo.values.map(item => <label key={item.value} className="pointer w-100 m-0 p-h-xs"><input checked={defaultValue === item.value} name={this.props.fieldInfo.optionId} onChange={() => this.props.defaultOnChange(this.updateDefaultValue(this.props.fieldInfo.optionId, item.value))} readOnly={parameters.readOnly} type="radio" /> {item.name}</label>)}
      </div>
    )
  }

  addShowText(parameters) {
    return (
      <div className="mb-1 p-sm bg-widget">
        <span>{this.props.fieldInfo.optionName}</span>
      </div>
    )
  }

  addShowUrl(parameters) {
    return (
      <div className="mb-1 p-sm bg-widget">
        <a href={parameters.url} rel="noreferrer" target="_blank"><h4>{this.props.fieldInfo.optionName}</h4></a>
      </div>
    )
  }

  addAlertDatePicker(defaultValue, parameters) {
    let parsedValue = /^((?:0[1-9])|(?:[1-2][0-9])|(?:3[0-1]))\.((?:0[1-9])|(?:1[0-2]))\.(\d{4})$/.exec(defaultValue);

    return (
      <div className="mb-1 p-sm bg-widget">
        {this.props.fieldInfo.optionName && <h4>{this.props.fieldInfo.optionName}</h4>}
        <input className="form-control form-input" defaultValue={parsedValue && parsedValue[3] + '-' + parsedValue[2] + '-' + parsedValue[1]} onChange={event => this.props.defaultOnChange(this.updateDefaultValue(this.props.fieldInfo.optionId, this.dateFormat(event.target.value)))} readOnly={parameters.readOnly} type="date" />
      </div>
    )
  }

  addAlertTimePicker(defaultValue, parameters) {
    let patternValue = /^((?:[0-1][0-9])|(?:2[0-3])):([0-5][0-9])$/;
    let parsedValue = patternValue.exec(defaultValue);

    return (
      <div className="mb-1 p-sm bg-widget">
        {this.props.fieldInfo.optionName && <h4>{this.props.fieldInfo.optionName}</h4>}
        <input className="form-control form-input" defaultValue={parsedValue && defaultValue} onChange={event => this.props.defaultOnChange(this.updateDefaultValue(this.props.fieldInfo.optionId, patternValue.test(event.target.value) && event.target.value))} readOnly={parameters.readOnly} type="time" />
      </div>
    )
  }

  render() {
    let defaultValue = this.props.defaults[this.props.fieldInfo.optionId] || '';
    let parameters = this.props.fieldInfo.parameters || {};
    switch(this.props.fieldInfo.type) {
      case 'alertCheckBox':
        return this.addAlertCheckBox(defaultValue, parameters);
      case 'alertEditText':
        return this.addAlertEditText(defaultValue, parameters);
      case 'alertNumberText':
        return this.addAlertNumberText(defaultValue, parameters);
      case 'alertPhoneNumber':
        return this.addAlertPhoneNumber(defaultValue, parameters);
      case 'alertPrice':
        return this.addAlertPrice(defaultValue, parameters);
      case 'alertPriceRange':
        return this.addAlertPriceRange(defaultValue, parameters);
      case 'alertRadioButton':
        return this.addAlertRadioButton(defaultValue, parameters);
      case 'alertRange':
        return this.addAlertRange(defaultValue, parameters);
      case 'categories':
        return this.addCategories(defaultValue, parameters);
      case 'browseFile':
        return this.addBrowseFile(defaultValue, parameters);
      case 'checkBox':
        return this.addCheckBox(defaultValue, parameters);
      case 'contractBox':
        return this.addContractBox(defaultValue, parameters);
      case 'radioButton':
        return this.addRadioButton(defaultValue, parameters);
      case 'showText':
        return this.addShowText(parameters);
      case 'showUrl':
        return this.addShowUrl(parameters);
      case 'alertDatePicker':
        return this.addAlertDatePicker(defaultValue, parameters);
      case 'alertTimePicker':
        return this.addAlertTimePicker(defaultValue, parameters);
      default:
        console.log(this.props.fieldInfo.type);
    }
  }
}

export class CustomWidgetPrepare extends React.Component {
  render() {
    switch(this.props.fieldInfo.type) {
      case 'chooseRegion':
        console.log('chooseRegion');
      break;
      default:
        return <CustomWidget defaults={this.props.defaults} defaultOnChange={this.props.defaultOnChange} fieldInfo={this.props.fieldInfo} />
    }
  }
}