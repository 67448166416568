import React from 'react';
import swal from 'sweetalert';
import {Link} from 'react-router-dom';

import clientData from '../../inc/clientData';
import config from '../../inc/config';
import AddedConsultantModal from '../../components/AddedConsultantModal';
import Images from '../../utils/Images';

class ConsultantModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpenAddedConsultantModal: false,

      consultants: []
    };
  }

  componentDidMount() {
    this.getConsultants();
    window.scrollTo(0, 0);
    console.log("Consultants: componentDidMount()");
  }

  componentWillUnmount() {
    console.log("Consultants: componentWillUnmount()");
  }

  getConsultants() {
    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
    };

    fetch(config.restApi + 'consultants/tr', requestOptions)
    .then(response => response.json())
    .then(responseData => responseData.status === 200 ? this.setState({isLoading: false, consultants: responseData.consultants}) : swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: responseData.message, buttons: 'Tamam'}).then(() => this.props.navigate('/')), () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Yeniden Dene'}).then(() => this.getConsultants()));
  }

  deleteConsultantOnClick = (id) => {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'Danışmanı silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
        };

        fetch(config.restApi + 'consultant/tr/' + id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'Danışman başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getConsultants());
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  onCompleted = () => {
    this.getConsultants();
    this.setState({isOpenAddedConsultantModal: false});
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="mt-2">
        <div className="row">
          <div className="col d-flex justify-content-between">
            <h3 className="text-darkblue">Danışmanlarım</h3>
            <button className="btn border border-orange mr-2 mb-2" onClick={() => this.setState({isOpenAddedConsultantModal: true})} title="Ekle"><i className="fa fa-plus text-orange"></i></button>
          </div>
        </div>
        <AddedConsultantModal isOpen={this.state.isOpenAddedConsultantModal} modalDescription="Danışman eklemek için aşağıdaki alanları doldurunuz !" modalTitle="Danışman Ekle" onCompleted={this.onCompleted} onRequestClose={() => this.setState({isOpenAddedConsultantModal: false})} />
        <div className="hr-line-dashed mt-0"></div>
        <div className="row">
          <div className="col my-2">
            <div className="table-responsive-lg">
              <table className="table table-bordered text-center">
                {this.state.consultants.length !== 0 &&
                  <thead>
                    <tr className="text-darkblue">
                      <th>Adı Soyadı</th>
                      <th>Email</th>
                      <th>İşlem</th>
                    </tr>
                  </thead>
                }
                <tbody>
                  {this.state.consultants.map((consultantInfo) =>
                    <tr key={consultantInfo.id}>  
                      <td className="align-middle">{consultantInfo.firstname + ' ' + consultantInfo.lastname}</td>
                      <td className="align-middle">{consultantInfo.email}</td>
                      <td className="align-middle" style={{minWidth: 103}}>
                        <Link className="btn border border-orange m-1" title="Danışmana Ait İlanlar" to={'/consultantAdverts?consultantId=' + consultantInfo.id}><i className="fa fa-eye text-orange"></i></Link>
                        <button className="btn border border-darkblue text-center" title="Sil" onClick={() => this.deleteConsultantOnClick(consultantInfo .id)}><i className="fa fa-trash text-darkblue"></i></button>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {this.state.consultants.length === 0 &&
                <div className="col-12 d-block notFoundImg">
                  <div className="text-center">
                    <img className="m-4" src={Images.consultantNotFound}/>
                  </div>
                  <div className="text-center my-auto">
                    <h2 className="mb-1 mt-2"><b className="text-orange">Danışman Bulunamadı!</b></h2>
                    <h2 className="m-0 pb-4 text-darkblue">Sistemimize kayıtlı size atanmış danışman bulunmadı.</h2>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
  }
}

export default function Consultants() {
  return (
    <ConsultantModule />
  )
}