import React from 'react';

import clientData from '../../inc/clientData';
import config from '../../inc/config';
import {isoToDateTime} from '../../inc/helper';
import ReactPaginate from 'react-paginate';

class RemainingProductDetailsModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      products: [],

      pageCount: 0,
      resultCount: 0,
      currentPage: 1,
      limit: 10
    };
  }

  componentDidMount() {
    this.getProducts(this.state.currentPage);
    window.scrollTo(0, 0);
    console.log("RemainingProductDetails: ComponentDidMount()");
  }

  componentWillUnmount() {
    console.log("RemainingProductDetails: componentWillUnmount()");
  }

  getProducts(currentPage) {
    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
    };

    const startIndex = 0;
    const endIndex = startIndex + this.state.limit;

    let errorMessages = [];
    let remaining = 1;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        this.setState({isLoading: false});
      }
    }

    fetch(config.restApi + `accountProducts/tr?limit=${this.state.limit}&page=${currentPage}`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200: {
          const currentproducts = responseData.products.slice(startIndex, endIndex);
          this.setState({products: currentproducts, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
        }
        break;
        default: {
          errorMessages.push(responseData.message);
        }
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  handlePageClick(page) {
    this.setState({currentPage: page.selected + 1}, () => this.getProducts(this.state.currentPage))
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="mt-2">
        <div className="row">
          <div className="col">
            <h3 className="text-darkblue">Paket Detaylarım</h3>
          </div>
        </div>
        <div className="hr-line-dashed mt-0"></div>
        <div className="row mt-4">
          <div className="col-12">
            <div className="table-responsive-lg">
              <table className="table table-striped table-bordered text-center">
                <thead>
                  <tr className="text-darkblue">
                    <th>Ürün Adı</th>
                    <th>Ürün Hakkı</th>
                    <th>Ürün Başlangıç Tarihi</th>
                    <th>Ürün Bitiş Tarihi</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.products.map((productInfo) =>
                    <tr key={productInfo.id}>  
                      <td className="align-middle">{productInfo.name}</td>
                      <td className="align-middle">{productInfo.quantity} Adet</td>
                      <td className="align-middle">{isoToDateTime(productInfo.periodStart)}</td>
                      <td className="align-middle">{(isoToDateTime(productInfo.periodEnd))}</td>
                    </tr>
                    )
                  }
                  {this.state.products.length === 0 && <tr><td className="align-middle text-darkblue p-3" colSpan="4">Ürün Detayı Bulunamadı !</td></tr>}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {this.state.products.length !== 0 &&
        <ReactPaginate
          previousLabel={"Geri"}
          nextLabel={"İleri"}
          breakLabel={"..."}  
          pageCount={this.state.pageCount}
          marginPagesDisplayed={1}
          onPageChange={page => this.handlePageClick(page)}
          pageRangeDisplayed={2}
          containerClassName={'pagination'}
          activeClassName={'activePage'}
        />
        }
      </div>
  }
}

export default function RemainingProductDetails() {
  return (
    <RemainingProductDetailsModule />
  )
}