import React from 'react';
import {Link} from 'react-router-dom';
import swal from 'sweetalert';
import ReactPaginate from 'react-paginate';

import clientData from '../../inc/clientData';
import config from '../../inc/config';
import {isoToDate, priceFormat, slugify} from '../../inc/helper';
import Images from '../../utils/Images';

class AdvertsModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      adverts: [],

      pageCount: 0,
      resultCount: 0,
      currentPage: 1,
      limit: 10
    };
  }

  componentDidMount() {
    this.getAdverts(this.state.currentPage);
    window.scrollTo(0, 0);
    console.log("Adverts: componentDidMount()");
  }

  componentWillUnmount() {
    console.log("Adverts: componentWillUnmount()");
  }

  getAdverts(currentPage) {
    const requestOptions = {
    method: 'GET',
    headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
  };

  const startIndex = 0;
  const endIndex = startIndex + this.state.limit;

  this.setState({isLoading: true})

  fetch(config.restApi + `myAdverts/tr?limit=${this.state.limit}&page=${currentPage}`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          const currentAdverts = responseData.adverts.slice(startIndex, endIndex);
          this.setState({isLoading: false, adverts: currentAdverts, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  getAdvertStatus(id, title) {
    if(id === 1) {
      return <strong className="text-warning">Onay Bekliyor</strong>
    } else if(id === 2) {
      return <strong className="text-green">Yayında</strong>
    } else if(id === 3) {
      return <strong className="text-orange">Reddedildi</strong>
    } else if(id === 4) {
      if (title) {
        return <strong className="text-darkblue">Pasif</strong>;
      } else {
        return <strong className="text-darkblue">Taslak</strong>;
      }
    } else {
      return '-'
    }
  }

  deleteAdvertOnClick(id) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'İlanınızı silmek istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
        };

        fetch(config.restApi + 'advert/tr/' + id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'İlanınız başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getAdverts());
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  takeDownAdvertOnClick(advertId){
    swal({
      dangerMode: true,
      icon: 'info',
      title: 'Onayla',
      text: 'Yayından kaldırmak istediğinize emin misiniz ?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then((value) => {
      if (value) {
        const requestOptions = {
          method: 'PUT',
          headers: {'Authorization': 'Bearer ' + clientData.sessionHash, 'Content-Type': 'application/json'},
          body: JSON.stringify({advertStatus: 4})
        };

        fetch(config.restApi + 'advertStatus/tr/' + advertId, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200:
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Başarılı',
                text: 'İlan başarıyla yayından kaldırıldı.',
                buttons: 'Tamam'
              }).then(() => this.getAdverts());
            break;
            default:
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));

      }
    });
  }

  handlePageClick(page) {
    this.setState({currentPage: page.selected + 1}, () => this.getAdverts(this.state.currentPage));
    window.scrollTo(0, 0);
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="mt-2">
        <div className="row">
          <div className="col d-flex justify-content-between">
            <h3 className="text-darkblue">Tüm İlanlarım</h3>
            <strong className="text-orange">Toplam İlan Sayısı: {this.state.resultCount}</strong>
          </div>
        </div>
        <div className="hr-line-dashed mt-0"></div>
        <div>
          <Link className="btn btn-none border btn-darkgray bg-orange text-white mr-1" to={"/adverts"}>Tüm İlanlarım</Link>
          <Link className="btn btn-none border btn-darkgray bg-orange-btn text-black mr-1" to={"/activeAdverts"}>Yayında Olan İlanlarım</Link>
          <Link className="btn btn-none border btn-darkgray bg-orange-btn text-black mr-1" to={"/passiveAdverts"}>Yayında Olmayan İlanlarım</Link>
          <Link className="btn btn-none border btn-darkgray bg-orange-btn text-black" to={"/pendingAdverts"}>Onay Bekleyen İlanlarım</Link>
        </div>
        <div className="d-flex justify-content-end">
          <div className="btn-open btn border border-darkblue text-center mb-1 ">
            <span className="dropdown-toggle font-bold text-darkblue" data-toggle="dropdown"><i className="fa fa-ellipsis-v text-darkblue"></i></span>
            <ul className="dropdown-menu overflow-auto mr-3 mt-2">
              <li><Link className="dropdown-item font-bold" to={"/adverts"}> Tüm İlanlarım</Link></li>
              <li><Link className="dropdown-item font-bold" to={"/activeAdverts"}> Yayında Olan İlanlarım</Link></li>
              <li><Link className="dropdown-item font-bold" to={"/passiveAdverts"}> Yayında Olmayan İlanlarım</Link></li>
              <li><Link className="dropdown-item font-bold" to={"/pendingAdverts"}> Onay Bekleyen İlanlarım</Link></li>
            </ul>
          </div>
        </div>
        <div className="row d-none my-3">
          <div className="col">
            <div className="input-group mt-1 mt-lg-0">
              <input className="input form-control form-input" placeholder="Kelime veya ilan no veya giriniz" type="text" />
              <div className="input-group-append"><button className="btn bg-orange"><i className="fa fa-search text-white"></i></button></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col my-2">
            <div className="table-responsive-lg mb-4">
              <table className="table table-bordered text-center">
                {this.state.adverts.length !== 0 &&
                  <thead>
                    <tr className="text-darkblue">
                      <th>İlan Görseli</th>
                      <th>İlan Adı</th>
                      <th>İlan Sahibi</th>
                      <th>İlanı Ekleyen</th>
                      <th>İlan Fiyatı</th>
                      <th>İlan Tarihi</th>
                      <th>Bölge</th>
                      <th>İlan Durumu</th>
                      <th>İşlem</th>
                    </tr>
                  </thead>
                }
                <tbody className="text-center">
                  {this.state.adverts.map((advertInfo) =>
                    <tr key={advertInfo.id}> 
                      <td className="align-middle">
                        {advertInfo.photos.length > 0 ? <img alt="İlan Görseli" className="w-md-50" style={{height: 100, width:200, objectFit:"contain", overflow: "visible"}} src={`https://file.satisgaranti.com/${advertInfo.photos[0].path}`}/>
                        : <i className="fa fa-camera d-flex align-items-center justify-content-center" style={{height: 100, objectFit:"contain", overflow: "visible", fontSize: 80, color: '#d2d2d2'}}></i>}<br /><strong className="text-darkblue font-bold"> İlan No: #{advertInfo.id}</strong>
                      </td>
                      <td className="align-middle">{advertInfo.title ? < Link target='_blank' to={`https://${config.host}/ilan/${slugify(advertInfo.title)}-${advertInfo.id}/detay`}>{advertInfo.title}</Link> : <Link>İlan başlığı girilmedi</Link>}<br />
                        <div className="mt-3 d-block d-lg-flex justify-content-center">
                          <div><span className="icon-point pointer" title="Favoriler"><i className="fa fa-heart text-danger"></i> {advertInfo.favoriteCount}</span></div>
                          <div><span className="pointer" title="Görüntüleme"><i className="fa fa-eye text-green"></i> {advertInfo.views}</span></div>
                        </div>
                      </td>
                      <td className="align-middle">{advertInfo.memberFullName || '-'}</td>
                      <td className="align-middle">{advertInfo.addedFullName || '-'}</td>
                      <td className="align-middle">{priceFormat(advertInfo.price)} {advertInfo.currencySymbol}</td>
                      <td className="align-middle">{isoToDate(advertInfo.addedDate)}</td>
                      <td className="align-middle">{advertInfo.townName + ' / ' + advertInfo.cityName}</td>
                      <td className="align-middle">{this.getAdvertStatus(advertInfo.advertStatus, advertInfo.title)}</td>
                      <td className="align-middle">
                        <div className="d-inline-grid justify-content-center">
                          {advertInfo.title && (advertInfo.advertStatus === 2 || advertInfo.advertStatus === 4) ? <Link className="btn border border-green text-center mb-1" target='_blank' title="Kredi İşlemi" to={`https://kredi.${config.host}/creditProviders?id=` + advertInfo.id}><i className="fa fa-money text-green"></i></Link> : <Link className="btn border mb-1" title="Sadece yayında veya pasif durumdaki ilan için başvuru yapabilirsiniz."><i className="fa fa-money"></i></Link>}
                          <Link className="btn border border-orange text-center mb-1" title="Düzenle" to={'/advertEdit?id=' + advertInfo.id}><i className="fa fa-edit text-orange"></i></Link>
                          <div className="btn border border-darkblue text-center mb-1">
                            <span className="dropdown-toggle font-bold text-darkblue" data-toggle="dropdown"><i className="fa fa-cogs text-darkblue"></i></span>
                            <ul className="dropdown-menu overflow-auto mr-3 mt-2">
                              <li><Link className="dropdown-item font-bold" onClick={() => this.deleteAdvertOnClick(advertInfo.id)}><i className="fa fa-trash text-darkblue m-r-xs"></i> Sil</Link></li>
                              <li><Link className="dropdown-item font-bold" target='_blank' to={`https://${config.host}/ilan/${slugify(advertInfo.title)}-${advertInfo.id}/detay`}><i className="fa fa-external-link text-darkblue m-r-xs"></i> İlan detayına git</Link></li>
                              {/* <li><Link className="dropdown-item font-bold" title="Danışman Ekle" to={'/consultants'}><i className="fa fa-user text-darkblue m-r-xs"></i> Danışman Ekle</Link></li> */}
                            </ul>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                  {this.state.adverts.length === 0 &&
                    <div className="col-12 d-block notFoundImg">
                      <div className="text-center">
                        <img className="m-4" src={Images.advertNotFound}/>
                      </div>
                      <div className="text-center my-auto">
                        <h2 className="mb-1 mt-2"><b className="text-orange">İlan Bulunamadı!</b></h2>
                        <h2 className="m-0 pb-4 text-darkblue">Şu anda ilanınız bulunmamaktadır.</h2>
                      </div>
                    </div>
                  }
                </tbody>
              </table>
            </div>
            {this.state.adverts.length !== 0 &&
            <ReactPaginate
              previousLabel={"Geri"}
              nextLabel={"İleri"}
              breakLabel={"..."}  
              pageCount={this.state.pageCount}
              marginPagesDisplayed={1}
              onPageChange={page => this.handlePageClick(page)}
              pageRangeDisplayed={2}
              containerClassName={'pagination'}
              activeClassName={'activePage'}
              forcePage={this.state.currentPage - 1}
            />
            }
          </div>
        </div>
      </div>
  }
}

export default function Adverts() {
  return (
    <AdvertsModule />
  )
}
