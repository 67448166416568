import React, { Component } from 'react'

export default class NotFoundPage extends Component {

  componentDidMount() {
    console.log("NotFoundPage: ComponentDidMount()")
  }

  componentWillUnmount() {
    console.log("NotFoundPage: componentWillUnmount()")
  }

  render() {
    return (
      <div className="middle-box text-center">
        <h1 className="text-darkblue">404</h1>
        <h3 className="font-bold text-darkblue">Sayfa Bulunamadı!</h3>
        <div className="error-desc text-darkblue">
          URL'de hata olup olmadığını kontrol etmeyi deneyin, ardından tarayıcınızdaki yenile düğmesine basın veya uygulamamızda başka bir şey bulmayı deneyin.
          <form className="form-inline m-t justify-content-center" role="form">
            <div className="form-group">
              <input type="text" className="form-control" placeholder="Arama kriteriniz giriniz" />
            </div>
            <button type="submit" className="btn btn-darkblue">Ara</button>
          </form>
        </div>
      </div>
    )
  }
}
