import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';

import clientData from '../../inc/clientData';
import config from '../../inc/config';
import Images from '../../utils/Images';
import {isoToDateTime, isoToDate, slugify, isoToMysqlTime} from '../../inc/helper';

class MessageDetailModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      memberId: 0,
      comment: '', 
      messageDetails: [],
      conversationInfo: {}

    };
    this.chatScrollToBottom = React.createRef();
  }

  componentDidMount() {
    this.getMessageDetails();
    this.scrollToBottom();
    window.scrollTo(0, 0);
    console.log("MessageDetails: componentDidMount()");
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.messageDetails.length < this.state.messageDetails.length) {
      const chatContainer = this.chatScrollToBottom.current;
      if (chatContainer) {
        const isUserReadingHistory = chatContainer.scrollHeight - chatContainer.scrollTop !== chatContainer.clientHeight && chatContainer.scrollTop < 1600;
        if (isUserReadingHistory) {
        } else {
          this.scrollToBottom();
        }
      }
    }
  }

  componentWillUnmount() {
    console.log("MessageDetails: componentWillUnmount()");
  }

  scrollToBottom = () => {
    const chatContainer = this.chatScrollToBottom.current;
    if (chatContainer) {
      const { scrollTop, scrollHeight, clientHeight } = chatContainer;
      if (scrollTop + clientHeight !== scrollHeight) {
        chatContainer.scrollTop = scrollHeight - clientHeight;
      }
    } else {
      setTimeout(this.scrollToBottom, 100);
    }
  };

  getMessageDetails() {
    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
    };

    let errorMessages = [];
    let remaining = 2;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        this.setState({isLoading: false});
        if (errorMessages.length > 0) {
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: errorMessages[0],
            buttons: 'Tamam'
          });
        }
      }
    }

    fetch(config.restApi + 'memberInfo', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          this.setState({memberId: responseData.memberInfo.id});
        break;
        default:
          errorMessages.push(responseData.message);
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));

    fetch(config.restApi + 'messageDetails/tr/' + this.props.searchParams.get('id'), requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          this.setState({conversationInfo: responseData.conversationInfo, messageDetails: responseData.messageDetails});
        break;
        default:
          errorMessages.push(responseData.message);
        break;
      }
      complete();
    }, () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.'));
  }

  saveCommentOnClick = () => {
    let infoMessage = '';

    if (!this.state.comment) {
      infoMessage = 'Mesaj boş geçilemez !';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer ' + clientData.sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({comment: this.state.comment})
      };

      fetch(config.restApi + 'message/tr/' + this.props.searchParams.get('id'), requestOptions)
      .then(response => response.json())
      .then(responseData => responseData.status === 200 ? this.setState({comment: ''}, () => {this.getMessageDetails()}) : swal({dangerMode: true, icon: 'warning', title: 'Hay Aksi', text: responseData.message, buttons: 'Tamam'}),/* .then(() => this.props.navigate('/')), */ () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
    }
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="ibox">
          <div className="ibox-title text-darkblue d-flex justify-content-between pr-2">
            <h4 className="text-darkblue">Mesajlarım</h4>
            <div className="d-flex">
              <button className="btn border border-darkblue text-darkblue" onClick={() => this.props.navigate(-1)} title="Geri"><i className="fa fa-arrow-left"></i> Geri Dön</button>
            </div>
          </div>
          <div className="ibox-content">
            <div className="row">
              <div className="col">
                <h4 className="text-darkblue">İlan Detayı</h4>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col">
                <div className="table-responsive-lg">
                  <table className="table table-bordered text-center">
                    <thead>
                      <tr className="text-darkblue">
                        <th>İlan Görseli</th>
                        <th>İlan Başlığı</th>
                        <th>Mesajlaşma Tarihi</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      <tr>
                        <td className="align-middle">
                          {this.state.conversationInfo.advertPhotos.length > 0 ? <img alt="İlan Görseli" className="w-md-50" style={{height: 100, width:200, objectFit:"contain", overflow: "visible"}} src={`https://file.satisgaranti.com/${this.state.conversationInfo.advertPhotos[0].path}`}/>
                          : <i className="fa fa-camera d-flex align-items-center justify-content-center" style={{height: 100, objectFit:"contain", overflow: "visible", fontSize: 48, color: '#d2d2d2'}}></i>}<br /><strong className="text-darkblue font-bold"> İlan No: #{this.state.conversationInfo.advertId}</strong>
                        </td>
                        <td className="align-middle"><Link target='_blank' to={`https://${config.host}/ilan/${slugify(this.state.conversationInfo.advertTitle)}-${this.props.searchParams.get('id')}/detay`}>{this.state.conversationInfo.advertTitle}</Link></td>
                        <td className="align-middle">{isoToDateTime(this.state.conversationInfo.modifiedDate)}</td>
                      </tr>
                    }
                    {this.state.conversationInfo.length === 0 && <tr><td className="align-middle p-3 text-darkblue" colSpan="3">İlan Detayı Bulunamadı !</td></tr>}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="card">
                  <div className="card-body message-history" ref={this.chatScrollToBottom} style={{backgroundImage: 'url(' + Images.messageBackground + ')'}}>
                    {this.state.messageDetails.map((messageDetailInfo) => messageDetailInfo.addedBy === this.state.memberId ?
                      <div className="d-flex flex-column" key={messageDetailInfo.id}> 
                      <div className="d-flex justify-content-end text-dark mr-1"><h6 className="mb-0">{isoToDate(messageDetailInfo.addedDate)}</h6></div>
                        <div className="d-flex justify-content-end">
                          <div className="pl-2 pt-1 mb-3 btn-sm bg-softgreen" style={{width: messageDetailInfo.comment.length < 50 ? 'auto' : '50%', height: 'auto'}}>
                            {messageDetailInfo.comment}
                            <div className="d-flex justify-content-end ml-5"><h6 className="mb-0">{isoToMysqlTime(messageDetailInfo.addedDate)}</h6></div>
                          </div>
                        </div>
                      </div>
                      :
                      <div className="d-flex flex-column mb-2" key={messageDetailInfo.id}>
                        <div className="d-flex"><strong className="mr-2">{messageDetailInfo.addedFullName}</strong><span className="d-flex align-items-center"><h6 className="mb-0">{isoToDate(messageDetailInfo.addedDate)}</h6></span></div>
                        <div className="d-flex justify-content-start">
                          <div className="pl-2 pt-1 btn-sm bg-blue text-dar" style={{width: messageDetailInfo.comment.length < 50 ? 'auto' : '50%', height: 'auto'}}>
                            {messageDetailInfo.comment}
                            <div className="d-flex justify-content-end ml-5"><h6 className="mb-0">{isoToMysqlTime(messageDetailInfo.addedDate)}</h6></div>
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.messageDetails.length === 0 && <div className="d-flex justify-content-center">Mesaj Geçmişi Bulunamadı !</div>}
                  </div>  
                </div>
                <div className="mt-3">
                  <div className="input-group">
                    <input className="form-control form-input" onChange={(event) => this.setState({comment: event.target.value})} onKeyDown={(event) => event.key === 'Enter' && this.saveCommentOnClick()} placeholder="Mesaj giriniz.." value={this.state.comment} />
                    <div className="input-group-append">
                      <button className="input-group-text" onClick={this.saveCommentOnClick}><i className="fa fa-send-o text-darkblue"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  }
}

export default function MessageDetails(props) {
    return (
      <MessageDetailModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
    )
  }