import React from 'react';
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import swal from 'sweetalert';

import SelectConsultantModal from '../../components/SelectConsultantModal';

import config from '../../inc/config';
import {getCookie} from '../../inc/cookies';
import clientData from '../../inc/clientData';

let categories = [];
let selectedCategoryId = null;

class CreateAdvertModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isOpenSelectConsultantModal: false,

      isShowContinueButton: false,
      isShowButton: false,

      categories: categories,
      selectedCategoryId: selectedCategoryId,
      selectedCategoryName: '',

      selectedCategories: [],
      consultantCompanies: [],

      memberId: clientData.id,

      email: 0,
      identity: 0,
      phone:0
    };
  }

  componentDidMount() {
    if (categories.length === 0) {
      this.getCategories(0, 0, '');
    }
    window.scrollTo(0, 0);
    console.log("createAdvert: componentDidMount()");
    this.getCategories(0, 0, '');
  }

  componentWillUnmount() {
    localStorage.setItem('selectedCategories', JSON.stringify(this.state.selectedCategories));
    console.log("createAdvert: componentWillUnmount()");
  }

  getCategories(categoryId, depth, categoryName) {
    this.selectedCategories(categoryId, depth, categoryName);
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + sessionHash },
    };

    let errorMessages = [];
    let remaining = 3;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        this.setState({ isLoading: false });
      }
    };

    fetch(config.restApi + 'categories/tr/' + categoryId, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          let categories = this.state.categories;

          while (depth < categories.length) {
            categories.pop();
          }

          categories[depth] = responseData.categories;
          this.setState({categories: categories});
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        break;
      }
          complete();
        }, () => swal({ dangerMode: true, icon: "error", title: "Bağlantı Hatası", text: "Lütfen internet bağlantınızı kontrol ediniz.", buttons: "Tamam"})
      );

      fetch(config.restApi + 'consultantCompanies/tr', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch(responseData.status) {
          case 200: {
            this.setState({consultantCompanies: [{id: clientData.id, name: 'Kendi Hesabım için yayınla'}, ...responseData.consultantCompanies]});
          }
          break;
          default: {
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            }).then(() => this.props.navigate('/home'));
          }
          break;
        }
        complete();
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));

      fetch(config.restApi + 'validationStatus', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch (responseData.status) {
          case 200:
            this.setState({email: responseData.email, identity: responseData.identity, phone: responseData.phone});
          break;
          default:
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            }).then(() => this.props.navigate('/'));
          break;
        }
          complete();
        },
        () => errorMessages.push('Lütfen internet bağlantınızı kontrol ediniz.')
      );
  }
   
  selectedCategories(categoryId, depth, categoryName) {
    const selectedCategories = this.state.selectedCategories.slice(0, depth);
    selectedCategories.push({categoryId, categoryName});
    this.setState({selectedCategoryId: categoryId, selectedCategoryName: categoryName, selectedCategories: selectedCategories, isShowContinueButton: false});
  }

  getConsultantCompaniesOnClick = () => {
    this.state.consultantCompanies.length === 1 ? this.setState({isOpenSelectConsultantModal: true}) /* this.saveOnClick() */ : this.setState({isOpenSelectConsultantModal: true})
  }

  saveOnClick = () => {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({categoryId: this.state.selectedCategoryId, memberId: this.state.memberId})
    };
    
    this.setState({isLoading: true});

    fetch(config.restApi + 'advert/tr', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200: {
          this.setState({isLoading: false});
          window.location.href= `/advertEdit?id=${responseData.advertId}`;
        }
        
        break;
        default: {
          this.setState({isLoading: true});
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        }
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  scrollOffset = () => {
    setTimeout(() => {
      const element = document.getElementById("offset");
      if (element) {
        element.scrollLeft += 500;
      }
    }, 200);
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="mt-2">
        <div className="row">
          <div className="col">
            {this.state.identity === 0 || this.state.email === 0 || this.state.phone === 0 ?
              <h3 className="text-darkblue">İlan Ver</h3> : <h3 className="text-darkblue">Kategori Seçimi</h3>
            }
          </div>
        </div>
        <div className="hr-line-dashed mt-0"></div>
        <div className="row">
          <div className="col-12">
            <div className="ml-sm-0">
              {this.state.identity === 1 || this.state.email === 1 || this.state.phone === 1 &&
                <div className="row mb-2">
                  <div className="col">
                    <ul className="d-flex list-unstyled">
                      {this.state.selectedCategories.map((category, index) => (
                        category.categoryId > 0 || index > 0 ? 
                        <li key={index} className="breadcrumb-item">
                          <a className="text-orange" href="#" >
                            {category.categoryName}
                          </a>
                        </li>
                        : null
                      ))}
                    </ul>
                  </div>
                </div>
              }
              {this.state.identity === 0 || this.state.email === 0 || this.state.phone === 0 ?
                <div>
                  <h4 className="text-darkblue col-md-6 p-0">İlan yayınlamak için e-posta, e-devlet, telefon numarası doğrulaması gerekmektedir. Aşağıda doğrulanmamış linke tıklayarak ilerleyebilirsiniz.</h4>
                  <div>
                    <Link className="btn border border-0 text-orange my-2" title="E-posta Doğrulaması" to={'/mailValidation'}>E-posta Doğrulaması: {this.state.email === 0 ? <i className="fa fa-close text-danger ml-1"></i> : <i className="fa fa-check text-green ml-1"></i>}</Link>
                  </div>
                  <div>
                    <Link className="btn border border-0 text-orange my-2" title="e-Devlet Doğrulaması" to={'/identityValidation'}>e-Devlet Doğrulaması: {this.state.identity === 0 ? <i className="fa fa-close text-danger ml-1"></i> : <i className="fa fa-check text-green ml-1"></i>}</Link>
                  </div>
                  <div>
                    <Link className="btn border border-0 text-orange my-2" title="Telefon Doğrulaması" to={'/phoneValidation'}>Telefon Doğrulaması: {this.state.phone === 0 ? <i className="fa fa-close text-danger ml-1"></i> : <i className="fa fa-check text-green ml-1"></i>}</Link>
                  </div>
                </div>
                :
                <div className="row overflow-auto mb-3" id='offset' onClick={this.scrollOffset()}>
                  <div className="row col-12 d-flex flex-nowrap pl-4">
                    {this.state.categories.map((categoryList, depthIndex) => (
                      <div className="col-6 col-sm-3" key={depthIndex}>
                        <ul className="list-group text-center">
                          {categoryList.map((categoryInfo, categoryIndex) => (
                            <li 
                              className="list-group-item p-2 pointer" 
                              style={{
                                backgroundColor: this.state.selectedCategories.some(item => item.categoryId !== '' && item.categoryId === categoryInfo.id) ? '#e04403' : '',
                                color: this.state.selectedCategories.some(item => item.categoryId !== '' && item.categoryId === categoryInfo.id) ? 'white' : ''
                              }}
                              key={depthIndex + '-' + categoryIndex} 
                              onClick={() => {
                                if (categoryInfo.hasChild) {
                                  this.getCategories(categoryInfo.id, depthIndex + 1, categoryInfo.name)
                                } else {
                                  this.selectedCategories(categoryInfo.id, depthIndex + 1, categoryInfo.name);
                                  this.setState({isShowContinueButton: true});
                                }
                              }}>
                              {categoryInfo.name}
                              {categoryInfo.hasChild ? <i className="fa fa-chevron-right float-right mt-1"></i> : null}
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                    {this.state.isShowContinueButton &&
                      <div className="col-6 col-sm-3 text-center m-2">
                        <p className="text-green m-1"><strong>İlan Yayınlamak İçin Devam Ediniz !</strong></p>
                        {this.state.consultantCompanies.length === 1 ? <Link className="btn btn-green text-white px-2" to={"/selectConsultantPoint?id=" + this.state.selectedCategoryId}>Devam Et<i className="fa fa-arrow-right text-white ml-2"></i></Link> : <Link className="btn btn-green text-white px-2" to={"/selectConsultant?id=" + this.state.selectedCategoryId}>Devam Et<i className="fa fa-arrow-right text-white ml-2"></i></Link>}
                      </div>
                    }
                  </div>
                </div>
              }
              <SelectConsultantModal consultantCompanies={this.state.consultantCompanies} categoryId={this.state.selectedCategoryId} memberId={this.state.memberId} isOpen={this.state.isOpenSelectConsultantModal} modalTitle="İlan Sahibi Seçimi" modalDescription="Lütfen ilanı yayınlamak istediğiniz kişiyi seçiniz !" onRequestClose={() => this.setState({isOpenSelectConsultantModal: false})} />
            </div>
          </div>
        </div>
      </div>
  }
}

export default function CreateAdvert() {
  return (
    <CreateAdvertModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}