import React from 'react';
import swal from 'sweetalert';
import {Link} from 'react-router-dom';

import clientData from '../../inc/clientData';
import config from '../../inc/config';
import Images from '../../utils/Images';

class IdentityValidationSuccessModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,

      memberInfo: {
        firstname: '',
        lastname: '',
        username: '',
        image: null
      },

    };
  }

  componentDidMount() {
    this.getSessionInfo();
    window.scrollTo(0, 0);
    console.log("Consultants: componentDidMount()");
  }

  getSessionInfo() {
    
    const requestOptions = {
      method: 'GET',
      headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
    };

    fetch(config.restApi + 'memberInfo', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200:
          clientData.id = responseData.memberInfo.id
          clientData.email = responseData.memberInfo.email
          this.setState({isLoading: false, memberInfo: responseData.memberInfo});
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          }).then(() => this.props.navigate('/'));
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet basadadğlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  componentWillUnmount() {
    console.log("Consultants: componentWillUnmount()");
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="mt-2">
        <div className="row">
          <div className="col d-flex justify-content-between">
            <h3 className="text-darkblue">e-Devlet</h3>
          </div>
        </div>
        <div className="hr-line-dashed mt-0"></div>
        <div className="row">
          <div className="col my-2">
            <div className="table-responsive-lg">
              <h4 className="col-md-6 border border-green text-green p-2"><i className="fa fa-check mr-1"></i>Hesabınız <b>{this.state.memberInfo.firstname} {this.state.memberInfo.lastname}</b> olarak e-Devlet üzerinden başarıyla doğrulandı.</h4>
              <h4 className="col-md-6 p-0">Doğruladığınız hesap bilgisi hatalı ise e-Devlet üzerinden hesabınızı tekrar doğrulayabilirsiniz.</h4>
              <Link className="btn btn text-darkblue my-2 px-0" to={'https://eids.ticaret.gov.tr/oturum?firmaKodu=1C8004D9-59E7-40AA-9DAA-084CBFB68647'} title="Hesabını tekrar doğrula"><b>Hesabını tekrar doğrula</b></Link>
            </div>
          </div>
        </div>
      </div>
  }
}

export default function IdentityValidationSuccess() {
  return (
    <IdentityValidationSuccessModule />
  )
}