import React from 'react';
import {useNavigate} from 'react-router-dom';

import Images from '../utils/Images';
import config from '../inc/config';

class AnonymousFrameModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  componentDidMount() {
    console.log("AnonyMousFrame: componentDidMount()");
  }

  componentWillUnmount() {
    console.log("AnonyMousFrame: componentWillUnmount()");
  }

  render() {
    return (
      <>
        <nav className="navbar navbar-expand-lg bg-darkblue">
          <div className="container p-2">
            <a className="index.html" title="Logo"><img alt="logo" src={Images.wlogo} /></a>
            <button className="navbar-toggler bg-orange" data-toggle="collapse" data-target="#navbar-menü"><i className="fa fa-bars bg-orange text-white"></i></button>
            <div className="collapse navbar-collapse" id="navbar-menü">
              <ul className="nav navbar-nav navbar-right ml-auto">
                <li className="nav-item mr-md-2 d-none">
                  <div className="input-group mt-1 mt-lg-0">
                    <input className="input form-control form-input br-orange w-300" placeholder="Kelime, ilan no veya mağaza adı giriniz" type="text" />
                    <div className="input-group-append"><button className="btn btn bg-orange"><i className="fa fa-search text-white"></i></button></div>
                  </div>
                </li>
                <a href={"https://uye." + config.host + "/login?ref=banaozel"}>
                  <li className="nav-item mr-md-2 mt-1 mt-lg-0">
                    <button className="btn btn-outline font-bold br-white bg-transparent text-white w-100">Giriş Yap</button>
                  </li>
                </a>
                <a href={"https://uye." + config.host + "/register?ref=banaozel"}>
                  <li className="nav-item mr-md-2 mt-1 mt-lg-0">
                    <button className="btn btn-outline font-bold br-white bg-transparent text-white w-100">Kayıt Ol</button>
                  </li>
                </a>
                <a className="d-none" href="#">
                  <li className="nav-item mr-md-2 mt-1 mt-lg-0">
                    <button className="btn btn-outline font-bold text-white bg-orange w-100">Ücretsiz İlan Ver</button>
                  </li>
                </a>
              </ul>
            </div>
          </div>
        </nav>
        <div className="mainpageImage">
          <div className="text-center pt-4 mt-5">
            <h1 className="text-darkblue pt-4 font-weight-normal">Bireysel Ve Kurumsal işlemlerinize devam edebilmek için lütfen giriş yapınız.</h1>
          </div>
        </div>
      </>
    )
  }
}

export default function AnonymousFrame() {
  return (
    <AnonymousFrameModule navigate={useNavigate()}></AnonymousFrameModule>
  )
}