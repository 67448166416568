import React from 'react';
import ReactPaginate from 'react-paginate';

import swal from 'sweetalert';
import Images from '../../utils/Images';

import clientData from '../../inc/clientData';
import config from '../../inc/config';
import {isoToDate, priceFormat, slugify} from '../../inc/helper';
import { Link } from 'react-router-dom';

class FavoriteAdvertsModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,

      adverts: [],

      pageCount: 0,
      resultCount: 0,
      currentPage: 1,
      limit: 10
    };
  }

  componentDidMount() {
    this.getFavoriteAdverts(this.state.currentPage);
    window.scrollTo(0, 0);
    console.log("FavoriteAdverts: componentDidMount()");
  }

  componentWillUnmount() {
    console.log("FavoriteAdverts: componentWillUnmount()");
  }

  getFavoriteAdverts(currentPage) {
    const requestOptions = {
    method: 'GET',
    headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
  };

  const startIndex = 0;
  const endIndex = startIndex + this.state.limit;

  this.setState({isLoading: true})

  fetch(config.restApi + `favoriteAdverts/tr?limit=${this.state.limit}&page=${currentPage}`, requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch(responseData.status) {
        case 200:
          const currentAdverts = responseData.adverts.slice(startIndex, endIndex);
          this.setState({isLoading: false, adverts: currentAdverts, pageCount: responseData.pageCount, resultCount: responseData.resultCount, currentPage: currentPage});
        break;
        default:
          swal({
            dangerMode: true,
            icon: 'warning',
            title: 'Hay Aksi',
            text: responseData.message,
            buttons: 'Tamam'
          });
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  priceIncreaseRate(prevPrice, lastPrice) {
    const priceDifference = lastPrice - prevPrice;
    const priceChangePercentage = ((priceDifference / prevPrice) * 100).toFixed(2);

    return priceChangePercentage >= 0 ? <span className="text-green">{priceChangePercentage} % <i className="fa fa-arrow-up"></i></span>: <span>{priceChangePercentage} % <i className="fa fa-arrow-down"></i></span>
  }
  
  deleteFavoriteAdvertOnClick(id) {
    swal({
      dangerMode: true,
      icon: 'warning',
      title: 'Onayla',
      text: 'İlanı favorilerinizden çıkarmak istediğinize emin misiniz?',
      buttons: {confirm: 'Tamam', cancel: 'Vazgeç'}
    }).then(value => {
      if (value) {
        const requestOptions = {
          method: 'DELETE',
          headers: {'Authorization': 'Bearer ' + clientData.sessionHash}
        };

        fetch(config.restApi + 'favoriteAdvert/tr/' + id, requestOptions)
        .then(response => response.json())
        .then(responseData => {
          switch (responseData.status) {
            case 200: {
              swal({
                dangerMode: true,
                icon: 'success',
                title: 'Tamamlandı',
                text: 'İlan favroilerden başarıyla silindi.',
                buttons: 'Tamam'
              }).then(() => this.getFavoriteAdverts());
            }
            break;
            default: {
              swal({
                dangerMode: true,
                icon: 'warning',
                title: 'Hay Aksi',
                text: responseData.message,
                buttons: 'Tamam'
              });
            }
            break;
          }
        }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
      }
    });
  }

  priceFormat(price) {
    return new Intl.NumberFormat('tr-TR', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(price);
  }

  handlePageClick(page) {
    this.setState({currentPage: page.selected + 1}, () => this.getFavoriteAdverts(this.state.currentPage));
    window.scrollTo(0, 0);
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="mt-2">
        <div className="row">
          <div className="col">
            <h3 className="text-darkblue">Favori İlanlarım</h3>
          </div>
        </div>
        <div className="hr-line-dashed mt-0"></div>
        <div className="">
          <Link className="btn btn-none-xs border btn-darkgray bg-orange text-white mr-1" to={"/favoriteAdverts"}>Favori İlanlarım</Link>
          <Link className="btn btn-none-xs border btn-darkgray bg-orange-btn text-black mr-1" to={"/favoriteSearches"}>Favori Aramalarım</Link>
          <Link className="btn btn-none-xs border btn-darkgray bg-orange-btn text-black" to={"/favoriteSellers"}>Favori Satıcılarım</Link>
        </div>
        <div className="d-flex justify-content-end">
          <div className="btn-open-xs btn border border-darkblue text-center mb-1 ">
            <span className="dropdown-toggle font-bold text-darkblue" data-toggle="dropdown"><i className="fa fa-ellipsis-v text-darkblue"></i></span>
            <ul className="dropdown-menu overflow-auto mr-3 mt-2">
              <li><Link className="dropdown-item font-bold" to={"/favoriteAdverts"}> Favori İlanlarım</Link></li>
              <li><Link className="dropdown-item font-bold" to={"/favoriteSearches"}> Favori Aramalarım</Link></li>
              <li><Link className="dropdown-item font-bold" to={"/favoriteSellers"}> Favori Satıcılarım</Link></li>
            </ul>
          </div>
        </div>
        <div className="row my-3">
          <div className="col">
            <div className="table-responsive-lg mb-4">
              <table className="table table-bordered text-center">
                {this.state.adverts.length !== 0 &&
                  <thead>
                    <tr className="text-darkblue">
                      <th>İlan Görseli</th>
                      <th>İlan Başlığı</th>
                      <th>İlan Fiyatı</th>
                      <th>İlan Tarihi</th>
                      <th>Favori Bilgisi</th>
                      <th>Bölge</th>
                      <th>İlan Durumu</th>
                      <th>İşlem</th>
                    </tr>
                  </thead>
                }
                <tbody>
                  {
                    this.state.adverts.map((advertInfo) =>
                      <tr key={advertInfo.id}>  
                        {
                          advertInfo.advertStatus === 2 ?
                          <>
                            <td className="align-middle">
                              {advertInfo.photos.length > 0 ? <img alt="İlan Görseli" className="w-md-50" style={{ height: 100, width:200, objectFit:"contain", overflow: "visible"}} src={`https://file.satisgaranti.com/${advertInfo.photos[0].path}`}/>
                              : <i className="fa fa-camera d-flex align-items-center justify-content-center" style={{height: 100, objectFit:"contain", overflow: "visible", fontSize: 48, color: '#d2d2d2' }}></i>}<br /><strong className="text-darkblue font-bold"> İlan No: #{advertInfo.id}</strong>
                            </td>
                            <td className="align-middle text-green"><Link target='_blank' to={`https://${config.host}/ilan/${slugify(advertInfo.title)}-${advertInfo.id}/detay`}>{advertInfo.title}</Link></td>
                            <td className="align-middle text-green">{this.priceFormat(advertInfo.price)} {advertInfo.currencySymbol}</td>
                            <td className="align-middle text-green">{isoToDate(advertInfo.addedDate)}</td>
                            <td className="align-middle">
                              <strong className="text-orange font-bold">Favori Tarihi: </strong>
                              <span className="text-orange">{isoToDate(advertInfo.favoriteAddedDate)}</span><br/>
                              <span className="text-orange">Favori Fiyatı: </span>
                              <span className="text-orange font-bold">{advertInfo.favoritePrice ? priceFormat(advertInfo.favoritePrice) : ''} {advertInfo.favoriteCurrencySymbol}</span><br/>
                              <span className="text-orange pointer font-bold" title="Fiyat Artış Oranı">{advertInfo.price !== "0.00" ? this.priceIncreaseRate(advertInfo.price, advertInfo.favoritePrice) : ""}</span>
                            </td>
                            <td className="align-middle text-green">{advertInfo.townName + '/' + advertInfo.cityName}</td>
                            <td className="align-middle text-green">{advertInfo.advertStatus === 2 ? 'Yayında' : 'Yayında Değil'}</td>
                            <td className="align-middle"><button className="btn border border-darkblue" onClick={() => this.deleteFavoriteAdvertOnClick(advertInfo.id)} title="Favorilerimden Çıkarın"><i className="fa fa-trash text-darkblue"></i></button></td>
                          </>
                          :
                          <>
                            <td className="align-middle">{advertInfo.photos.length > 0 ? <img alt="İlan Görseli" className="w-md-25 blurred-image" style={{ height: 100, width:200, objectFit:"contain", overflow: "visible"}} src={`https://file.satisgaranti.com/${advertInfo.photos[0].path}`}/>
                              : <i className="fa fa-camera d-flex align-items-center justify-content-center" style={{height: 100, objectFit:"contain", overflow: "visible", fontSize: 48, color: '#d2d2d2' }}></i>}<br /><strong className="text-darkblue font-bold"> İlan No: #{advertInfo.id}</strong>
                            </td>
                            <td className="align-middle text-darkblue">{advertInfo.title}</td>
                            <td className="align-middle text-darkblue">{advertInfo.price} {advertInfo.currenySymbol}</td>
                            <td className="align-middle text-darkblue">{isoToDate(advertInfo.addedDate)}</td>
                            <td className="align-middle">
                              <strong className="text-darkblue">Favori Tarihi: </strong>
                              <span className="text-darkblue font-bold">{isoToDate(advertInfo.favoriteAddedDate)}</span><br/>
                              <strong className="text-darkblue">Favori Fiyatı: </strong>
                              <span className="text-darkblue font-bold">{advertInfo.favoritePrice} {advertInfo.favoriteCurrencySymbol}</span><br/>
                              <span className="font-bold" title="Fiyat Artış Oranı">{this.priceIncreaseRate(advertInfo.price, advertInfo.favoritePrice)}</span>
                            </td>
                            <td className="align-middle text-darkblue">{advertInfo.townName + '/' + advertInfo.cityName}</td>
                            <td className="align-middle text-darkblue">{advertInfo.advertStatus === 2 ? 'Yayında' : 'Yayında Değil'}</td>
                            <td className="align-middle"><button className="btn border border-darkblue" onClick={() => this.deleteFavoriteAdvertOnClick(advertInfo.id)} title="Favorilerimden Çıkarın"><i className="fa fa-trash text-darkblue"></i></button></td>
                          </>
                        }
                      </tr>
                  )}
                  {this.state.adverts.length === 0 &&
                    <div className="col-12 d-block notFoundImg">
                      <div className="text-center">
                        <img className="mb-4 mt-0" src={Images.favoriteAdvertNotFound}/>
                      </div>
                      <div className="text-center my-auto">
                        <h2 className="mb-1 mt-2"><b className="text-orange">Favori Listenizde İlan Bulunamadı!</b></h2>
                        <h2 className="m-0 pb-4 text-darkblue">İlgilendiğiniz ilanı favorilerinize eklemek istiyorsanız, ilan detay sayfasındaki "Favorilerime Ekle" butonuna tıklayınız.</h2>
                      </div>
                    </div>
                  }
                </tbody>
              </table>
            </div>
            {this.state.adverts.length !== 0 &&
            <ReactPaginate
              previousLabel={"Geri"}
              nextLabel={"İleri"}
              breakLabel={"..."}  
              pageCount={this.state.pageCount}
              marginPagesDisplayed={1}
              onPageChange={page => this.handlePageClick(page)}
              pageRangeDisplayed={2}
              containerClassName={'pagination'}
              activeClassName={'activePage'}
              forcePage={this.state.currentPage - 1}
            />
            }
          </div>
        </div>
      </div>
  }
}

export default function FavoriteAdverts() {
  return (
    <FavoriteAdvertsModule />
  )
}