import React from 'react';
import swal from 'sweetalert';

import {getCookie} from '../../inc/cookies';
import config from '../../inc/config';
import clientData from '../../inc/clientData';
import {  Link, useNavigate, useSearchParams } from 'react-router-dom';

class SelectConsultantModule extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        isLoading: false,
  
        memberId: clientData.id,
        isIndividualUser: false,
        isInstitutionalUser: false,
        consultantCompaniesName: 'Kendi Hesabım için yayınla',
        continueButton: false,
        isOtherUserAdvertPanelOpen: false,
  
        cities: [],
        towns: [],
        regions: [],
        consultantCompanies: [],
        files: [],
        
        limit: null,
        cityId: 0,
        townId: 0,
        districtId: 0,
        quarterId: 0
      };
    }
    
  componentDidMount() {
    this.getCities()
    window.scrollTo(0, 0);
    console.log("SelectConsultantModal: componentDidMount()");
  }

  componentDidUpdate(prevProps) {
    if (prevProps.categoryId !== this.props.categoryId) {
      this.setState({categoryId: this.props.categoryId});
    }
  }

  componentWillUnmount() {
    console.log("SelectConsultantModal: componentWillUnmount()");
  }

  getCities() {
    let sessionHash = getCookie('sessionHash');

    const requestOptions = {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + sessionHash },
    };

    let errorMessages = [];
    let remaining = 3;
    let complete = () => {
      remaining--;
      if (remaining === 0) {
        this.setState({ isLoading: false });
      }
    };

      fetch(config.restApi + 'consultantCompanies/tr', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch(responseData.status) {
          case 200: {
            this.setState({isLoading: false, consultantCompanies: [{id: clientData.id, name: 'Kendi Hesabım için yayınla'}, ...responseData.consultantCompanies]});
          }
          break;
          default: {
            this.setState({isLoading: false});
  
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            }).then(() => this.props.navigate('/home'));
          }
          break;
        }
      }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'}));
  }

  handleLinkClick = () => {
    this.setState({ continueButton: !this.state.continueButton });
  }

  openMyAdvertPanel = () => {
    this.setState({isMyAdvertPanelOpen: true, isOtherUserAdvertPanelOpen: false});
  };

  openOtherUserAdvertPanel = () => {
    this.setState({isMyAdvertPanelOpen: false, isOtherUserAdvertPanelOpen: true});
  };

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading position-static">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <div className="mt-2">
        <div className="row">
          <div className="col">
            <h3 className="text-darkblue">Danışman Seçimi</h3>   
          </div>
        </div>
        <div className="hr-line-dashed mt-0"></div>
        <div className="row">
          <div className="col">
            <div className="ml-sm-0">
              <div className="row mb-2">
                {/* <div className="col">
                  <ul className="d-flex list-unstyled">
                    {this.state.consultantCompanies.map((consultantCompanyInfo, index) => 
                      consultantCompanyInfo.categoryId > 0 || index > 0 ?
                      <li key={index} className="breadcrumb-item">
                        <a className="text-orange" href="#" >
                          {consultantCompanyInfo.name}
                        </a>
                      </li>
                     : null
                    )}
                  </ul>
                </div> */}
              </div>
              <div className="row mb-3">
                <div className="col-4 col-md-2">
                  <ul className="list-group text-center">
                    {this.state.consultantCompanies.map((consultantCompanyInfo, index) =>
                      <li
                        className="list-group-item p-2 pointer"
                        style={{
                          backgroundColor: consultantCompanyInfo.id === this.state.memberId ? '#e04403' : '',
                          color: consultantCompanyInfo.id === this.state.memberId ? 'white' : ''
                        }}
                        key={index}
                        onClick={() => {this.setState({ memberId: consultantCompanyInfo.id, consultantCompaniesName: consultantCompanyInfo.name })}}
                      >
                        {consultantCompanyInfo.name}
                        {consultantCompanyInfo.name ? <i className="fa fa-chevron-right float-right mt-1"></i> : null}
                      </li>
                    )}
                  </ul>
                </div>
                <div className="col-8 col-md-10">
                  <div className="text-center">
                    <p className="text-green"><strong>İlan Yayınlamak İçin Devam Ediniz !</strong></p>
                    <Link className="btn btn-green text-white px-2" to={"/selectConsultantPoint?memberId=" + this.state.memberId + "&id=" + this.props.searchParams.get("id")}>Devam Et<i className="fa fa-arrow-right text-white ml-2"></i></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  }
}

export default function SelectConsultant() {
  return (
    <SelectConsultantModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}